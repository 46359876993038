/*--------------------------------------------------------------
# E Waste Section
--------------------------------------------------------------*/
.privacySection {
  padding: 60px 0px;
}
.privacySection h1 {
  color: #ff7f00;
  font-size: 22px;
}
.privacySection p {
  line-height: 2;
  color: #3f3f3f;
}
