.sliderItem {
  /* background: url(../assets/images/yes-banner.jpg); */
  background-size: cover;
  display: flex !important;
  justify-content: center;
  align-items: center;
  max-height: fit-content;
}
.viewButton {
  padding: 10px 25px !important;
  background: #ff7f00 !important;
  border-radius: 15px !important;
  font-size: 13px;
  border: none;
  color: #fff !important;
  cursor: pointer;
}
@media (max-width: 600px) {
  .sliderItem {
    margin-top: 60px;
  }
  .bannerTitle {
    color: #ff7f00;
    font-size: 32px;
  }
  .bannerWrapper {
    align-items: center;
    flex-direction: column-reverse;
  }
  .viewButton {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .bannerTitle {
    color: #ff7f00;
    font-size: 60px;
  }
  .bannerWrapper {
    align-items: center;
  }
  .viewButton {
    width: 150px;
    text-align: center;
  }
}
.bannerWrapper h4 {
  color: #3f3f3f;
}
