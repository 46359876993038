.fixedPosition {
  position: sticky;
}
.category {
  padding: 80px 0px 30px 0;
  color: #3f3f3f;
}
.sectionTitle h2,
.sectionTitle h4 {
  margin: 0;
  padding: 0;
}
.sectionTitle h2 {
  color: #ff7f00;
}
.sectionTitle {
  margin-bottom: 30px;
}
.categoryItem {
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  margin: 8px;
}
.categoryImg {
  scale: 1;
  height: 100%;
}
.categoryItem:hover img {
  scale: 1.1;
  width: 100%;
}

.categoryItem:hover {
  border: 5px solid #ff7f00;
  cursor: pointer;
}
.itemCardWrapper {
  padding-bottom: 40px;
  padding: 8px;
}
.itemCardWrapper img {
  border-radius: 0px 30px 0 30px !important;
}
.productImg {
  background: #fff;
  border-radius: 0px 30px 0 30px !important;
}
.productImg h4 {
  margin: 0 !important;
  padding: 15px 0;
  font-size: 12px;
  text-align: center;
}
.productSlider {
  padding: 40px 0 0 0;
}
.viewAll {
  color: #ff7f00 !important;
  font-size: 14px;
  font-weight: 500;
  text-align: end;
  margin-top: 40px;
  margin-bottom: 20px;
}

.whatsAppIconLayout {
  position: fixed;
  right: 25px;
  bottom: 40px;
  z-index: 996;
}

.whatsAppIcon {
  width: 60px;
  height: 60px;
}

@media (max-width: 600px) {
  .categoryWrapper {
    text-align: center;
    font-size: 10px;
  }
}
@media (min-width: 992px) {
  .categoryWrapper {
    text-align: center;
    font-size: 18px;
  }
}
