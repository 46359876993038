body::-webkit-scrollbar {
  display: none;
}
.boxShadow {
  position: sticky;
  background: #fff;
  top: 40px;
  z-index: 3;
  box-shadow: 4px 4px 25px 1px rgb(0 0 0 / 15%);
}
.category {
  padding-top: 25px;
  padding-bottom: 15px;
  color: #3f3f3f;
}
.sectionTitle h2,
.sectionTitle h4 {
  margin: 0;
  padding: 0;
}
.sectionTitle h2 {
  color: #ff7f00;
}
.sectionTitle {
  margin-bottom: 15px;
}
.categoryWrapper {
  text-align: center;
  flex-wrap: nowrap !important;
  overflow-x: auto;
}
.categoryItem {
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  width: 30%;
}
.categoryItemWrapper h4 {
  font-size: smaller;
  margin-bottom: 0 !important;
}
.categoryImg {
  scale: 1;
}
.categoryItemWrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column !important;
  flex-wrap: wrap;
  align-items: center;
  margin: 4px;
}
.categoryItem:hover img {
  scale: 1.1;
  width: 100%;
}

.categoryItemActive {
  border: 3px solid #ff7f00;
}
/* ----------------------------------
Product Detail Page Design
------------------------------------ */
.productDetailSection {
  padding: 80px 0;
}
.productDetailSection {
  padding-top: 120px;
}
.productDetailSection h3 {
  margin: 0;
  margin-bottom: 15px;
  /* color: #3f3f3f; */
}
.productDetailSection p {
  color: #797979;
  line-height: 1.6;
}
.price {
  color: #ff7f00;
}
.productName {
  margin-top: 0;
}
.detailRow {
  border-bottom: 2px solid #d3d3d3;
  color: #3f3f3f;
}
.detailHead {
  background: #f3f3f3;
  padding: 15px 0 15px 10px;
  margin: 0;
}
.detailData {
  background: #fff;
  padding: 15px 0 15px 10px;
  margin: 0;
}
.relatedProduct {
  padding-top: 60px;
}
.relatedProduct h2 {
  color: #ff7f00;
  text-align: center;
}

.relatedProduct p {
  line-height: 1.8;
}
/* Item List */
.itemCardWrapper {
  padding-bottom: 40px;
}
.itemCardWrapper img {
  border-radius: 0px 30px 0 30px !important;
}
/* .productItem{
  min-height: 300px;
} */
.productImg {
  background: #fff;
  border-radius: 0px 30px 0 30px !important;
  cursor: pointer;
  min-width: 100%;
  background-position: center;
  background-size: cover;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media (min-width: 992px) {
  .productImg img {
    min-height: 268px;
  }
  .productsSection {
    padding-top: 40px;
    margin-bottom: 60px;
  }
}

@media (max-width: 992px) {
  .productImg img {
    min-height: 175px;
  }
  .productsSection {
    margin-bottom: 60px;
  }
  .categoryItemWrapper h4 {
    font-size: 12px;
  }
  .categoryItem {
    width: 65px;
  }
}
.productImg h4 {
  font-size: smaller;
  margin: 0;
  padding: 15px 0;
  text-align: center;
}
