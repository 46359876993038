/*--------------------------------------------------------------
# E Waste Section
--------------------------------------------------------------*/
.eWasteSection {
  padding: 60px 0px;
}
.eWasteSection h2 {
  color: #2b83cb;
}
.eWasteSection h1 {
  color: #343434;
  font-size: 20px;
}
.subHeading {
  color: #a52a2a;
}
.ewasteContents .icon {
  color: #2b83cb;
  padding-right: 10px;
}
.tableHeader {
  background-color: #ccc;
}
