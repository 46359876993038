.itemCardWrapper {
  padding-bottom: 40px;
  padding: 8px;
  cursor: pointer;
}
.itemCardWrapper img {
  border-radius: 0px 30px 0 30px !important;
}
.productImg {
  background: #fff;
  border-radius: 0px 30px 0 30px !important;
  cursor: pointer;
}
.productImg img{
  min-height: 268px;
  min-width: 100%;
  background-position: center;
  background-size: cover;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.productImg h4 {
  margin: 0;
  padding: 15px 0;
  font-size: 13px;
  text-align: center;
}

.productSlider {
  padding: 40px 0 0 0;
}
