a {
  color: #000;
  text-decoration: none !important;
}
.header a {
  color: #000;
  font-size: 15px;
}
.header {
  height: 70px;
}
.active {
  color: #ff7e00 !important;
  border-bottom: 3px solid #ff7e00 !important;
}
.WhatsappButton {
  cursor: pointer;
  background: #ff7e00;
  color: #fff !important;
  padding: 8px 15px;
  border-radius: 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.WhatsappButton svg {
  padding-right: 10px;
  font-size: 20px;
}
.appBar {
  height: 70px;
  overflow: hidden;
}
