.aboutUs {
  padding: 50px 0;
}
.aboutUs h2 {
  color: #ff7f00;
}
.aboutUs p {
  /* font-size: 16px; */
  line-height: 2;
  color: #3f3f3f;
}
