/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  background: #fff;
  color: #343434;
  font-size: 15px;
}

.footerTop {
  padding: 0px 0 30px 0;
}

.footerInfo {
  margin-top: 15px;
}

.footerInfo p {
  line-height: 20px;
  margin-bottom: 0;
  color: #343434;
  line-height: 1.4;
}
.socialLinks a {
  font-size: 20px;
  display: inline-block;
  color: #fff !important;
  margin-left: 15px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  background: #5e5e5e;
}
.socialLinks svg {
  display: flex;
  width: 100%;
  padding-top: 9px;
}
.socialLinks a:hover {
  color: #fff !important;
  text-decoration: none;
  background: #ff7f00;
}

.footerTop h4 {
  font-size: 20px;
  font-weight: 600;
  color: #ff7f00;
  position: relative;
  margin-bottom: 4px !important;
}

.footerLinks {
  line-height: 1.4;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

i {
  padding-right: 2px;
  color: #ff7f00;
  font-size: 14px;
  line-height: 1;
}

li {
  padding: 10px 0;
  width: 100%;
}

ul .link {
  color: #343434;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
  cursor: pointer;
  text-decoration: none;
}

.copyright {
  border-top: 2px solid #ffdcba;
  padding: 15px 0px;
  margin-top: 15px;
}
.copyright p {
  text-align: center;
  color: #343434;
  font-weight: 500;
}
.copyright p a {
  text-align: center;
  color: #ff7f00;
}
.contactNumbers a {
  font-size: 18px !important;
  list-style: none;
  padding: 0;
  color: #ff7f00 !important;
}
@media (max-width: 992px) {
  .copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .connectUs {
    width: 100%;
  }
  .footer {
    text-align: center;
  }
}
